import gql from "graphql-tag";
import { client } from "@/api/apollo";
import { PageConsult, PageConsultInput } from "./Services";
import { Item } from "./ItemService";
import { store } from "@/store";
import { ActionTypes } from "@/store/actions";
import { TipoEntrega } from "./TipoEntregaService";
import { clone, filterKeys, removeKeys } from "@/utils/object";
import { PessoaEndereco } from "./PessoaService";
import { TipoPagamento } from "./TipoPagamentoService";
import { Embalagem } from "./EmbalagemService";
import { User } from "./UserService";
import { PontoApoio } from "./PontoApoioService";
import { PerfilAfiliado } from "./PerfilAfiliadoService";

let instance: PedidoService;

export type PedidoStatus =
  | "pendente"
  | "pago"
  | "pagto_pendente"
  | "cancelado"
  | "em_preparacao"
  | "pronto_retirada"
  | "entregue"
  | "enviado";

export const pedidoStatus: Readonly<PedidoStatus[]> = Object.freeze([
  "pendente",
  "pago",
  "pagto_pendente",
  "cancelado",
  "em_preparacao",
  "pronto_retirada",
  "entregue",
  "enviado",
]);

export interface Pedido {
  id: number;
  total: number;
  totalItens: number;
  frete: number;
  desconto: number;
  userId: number;
  createdAt?: string;
  updatedAt?: string;
  observacao?: string;
  status: string | PedidoStatus;
  itens?: PedidoItem[];
  user?: User;
  entregas?: PedidoEntrega[];
  codigoRastreamento?: string;
  enderecoEntrega?: Endereco;
  endereco?: PessoaEndereco;
  historico?: PedidoStatusHistorico[];
  pagamentos?: PedidoPagamento[];
  embalagens?: PedidoEmbalagem[];
  dataPagamento?: string;
  pontoApoio?: PontoApoio;
  taxaAdesao?: number;
  tipo?: string;
  perfil?: PerfilAfiliado;
  freteCompensado?: boolean;
  fretecompValorCusto?: number;
  fretecompValorVenda?: number;
}

export interface Endereco {
  tipo?: string;
  cep?: string;
  logradouro?: string;
  enderecoNumero?: string;
  complemento?: string;
  referencia?: string;
  bairro?: string;
  cidade?: string;
  cidadeIbge?: string;
  uf?: string;
  contato?: string;
  longitude?: number;
  latitude?: number;
}

export interface PedidoPagamento {
  createdAt?: string;
  updatedAt?: string;
  status: string;
  valor: number;
  tipoPagamentoId?: number;
  tipoPagamento: TipoPagamento;
}

export interface PedidoStatusHistorico {
  createdAt?: string;
  updatedAt?: string;
  status: string;
  mensagem?: string;
}

export interface PedidoEmbalagem {
  embalagemId: number;
  embalagem: Embalagem;
  qtd: number;
}

export interface PedidoEntrega {
  id?: number;
  tipoEntregaId?: number;
  tipoEntrega: TipoEntrega;
  retirarLocal: boolean;
  transportadora: string;
  valor: number;
  servico: string;
  prazo: number;
  extra: any;
}

export interface PedidoItem {
  id?: number;
  itemId: number;
  item: Item;
  qtd: number;
  valor: number;
  total: number;
}

export type PedidoItemInput = Exclude<PedidoItem, "item" | "id">;

export interface PedidoInput {
  total: number;
  totalItens: number;
  frete?: number;
  desconto?: number;
  observacao?: string;
  enderecoId?: number;
  itens: PedidoItemInput[];
  fromCart?: boolean;
  entrega: PedidoEntregaInput;
  perfilId?: number;
}

export interface PedidoEntregaInput {
  tipoEntregaId: number;
  tipoEntrega: TipoEntrega;
  valor: number;
  servico: string;
  prazo: number;
  extra: any;
}

export interface CalcTipoEntrega {
  tipoEntregaId: number;
  tipoEntrega: TipoEntrega;
  retirarLocal: boolean;
  transportadora: string;
  valor: number;
  servico: string;
  prazo: number;
  extra: any;
  erro: string;
  prazoMin?: number;
  compensarFrete?: boolean;
  compfreteCompraMinAfiliado?: number;
  compfreteCompraMinPa?: number;
}

export function getInstance(): PedidoService {
  if (!instance) {
    instance = new PedidoService();
  }
  return instance;
}

const initialPedidoEntregaInput: Readonly<PedidoEntregaInput> = Object.freeze({
  tipoEntregaId: null!,
  tipoEntrega: null!,
  valor: null!,
  servico: null!,
  prazo: null!,
  extra: null!,
});

export const initialPedidoInput: Readonly<PedidoInput> = Object.freeze({
  total: 0,
  totalItens: 0,
  observacao: null!,
  enderecoId: null!,
  itens: [],
  fromCart: true,
  entrega: initialPedidoEntregaInput,
});

export interface PedidoPageConsultInput extends PageConsultInput {
  userId?: number;
  status?: string;
  listarCancelados?: boolean;
  localCompra?: string;
}

export interface DadosFaturamento extends Endereco {
  rgInsc?: string;
  documento: string;
  telefone?: string;
  email?: string;
}

export default class PedidoService {
  async find(variables: PedidoPageConsultInput): Promise<PageConsult<Pedido>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $userId: ID
            $q: String
            $status: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
            $listarCancelados: Boolean
            $localCompra: String
          ) {
            pedidos(
              userId: $userId
              first: $first
              page: $page
              q: $q
              status: $status
              listarCancelados: $listarCancelados
              localCompra: $localCompra
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                createdAt
                updatedAt
                status
                total
                tipo
                user {
                  id
                  code
                  name
                  avatar
                }
                pontoApoio {
                  id
                  apelido
                }
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { pedidos } } = result;
    return pedidos;
  }

  async criarPedido(input: PedidoInput): Promise<number> {
    const data = clone(input);
    data.entrega = filterKeys(data.entrega, [
      "tipoEntregaId",
      "valor",
      "servico",
      "prazo",
      "extra",
    ]);
    data.entrega.tipoEntregaId =
      typeof input.entrega.tipoEntrega.id === "string"
        ? parseInt(input.entrega.tipoEntrega.id)
        : input.entrega.tipoEntrega.id;
    data.enderecoId = typeof input.enderecoId === "string"
      ? parseInt(input.enderecoId)
      : input.enderecoId;

    const result = await client
      .mutate({
        variables: {
          input: data,
        },
        mutation: gql`
          mutation($input: PedidoInput!) {
            criarPedido(input: $input)
          }
        `,
      });
    if (input.fromCart) {
      store.dispatch(ActionTypes.UPDATE_CARRINHO);
    }
    const { data: { criarPedido } } = result;
    return criarPedido;
  }

  async getById(id: number): Promise<Pedido> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            pedido(id: $id) {
              id
              tipo
              createdAt
              updatedAt
              status
              total
              totalItens
              frete
              desconto
              observacao
              dataPagamento
              freteCompensado
              fretecompValorVenda
              itens {
                id
                itemId
                item {
                  id
                  nome
                  categoria {
                    nome
                  }
                }
                qtd
                valor
                total
              }
              codigoRastreamento
              entregas {
                valor
                servico
                retirarLocal
              }
              pagamentos {
                createdAt
                tipoPagamento {
                  descricao
                }
                valor
                status
              }
              historico {
                status
                createdAt
                mensagem
              }
              embalagens {
                qtd
                embalagem {
                  id
                  descricao
                }
              }
              enderecoEntrega {
                contato
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                tipo
              }
              endereco {
                id
                contato
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                tipo
              }
              user {
                id
                code
                name
                avatar
                pessoa {
                  apelido
                  cep
                  logradouro
                  enderecoNumero
                  complemento
                  bairro
                  cidade
                  uf
                  referencia
                  documento
                  rgInsc
                  telefone
                }
              }
              pontoApoio {
                id
                apelido
              }
              perfil {
                nome
              }
              taxaAdesao
            }
          }
        `,
      });
    const { data: { pedido } } = result;
    return pedido;
  }

  async cancelar(id: number, mensagem?: string): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          mensagem,
        },
        mutation: gql`
          mutation($id: ID! $mensagem: String) {
            cancelarPedido(id: $id mensagem: $mensagem)
          }
        `,
      });
    const { data: { cancelarPedido } } = result;
    return cancelarPedido;
  }

  async changeStatus(
    id: number,
    status: string,
    mensagem?: string,
    extra?: any,
  ): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          status,
          mensagem,
          extra: JSON.stringify(extra),
        },
        mutation: gql`
          mutation($id: ID! $status: String! $mensagem: String $extra: Json) {
            changePedidoStatus(id: $id status: $status mensagem: $mensagem extra: $extra)
          }
        `,
      });
    const { data: { changePedidoStatus } } = result;
    return changePedidoStatus;
  }

  async getDadosFaturamento(id: number): Promise<DadosFaturamento> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            r: pedido(id: $id) {
              id
              tipo
              enderecoEntrega {
                contato
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                tipo
              }
              user {
                id
                name
                email
                pessoa {
                  apelido
                  cep
                  logradouro
                  enderecoNumero
                  complemento
                  bairro
                  cidade
                  uf
                  referencia
                  documento
                  rgInsc
                  telefone
                }
              }
              pontoApoio {
                id
                nome
                apelido
                rgInsc
                documento
                email
                telefone
                whatsapp
                cep
                logradouro
                enderecoNumero
                complemento
                referencia
                bairro
                cidade
                uf
              }
            }
          }
        `,
      });
    const { data: { r } } = result;

    if (["pa", "pa_ativacao"].includes(r.tipo) && r.pontoApoio) {
      const pa = r.pontoApoio;
      return {
        rgInsc: pa.rgInsc,
        documento: pa.documento,
        cep: pa?.cep,
        logradouro: pa?.logradouro,
        enderecoNumero: pa?.enderecoNumero,
        complemento: pa?.complemento,
        referencia: pa?.referencia,
        bairro: pa?.bairro,
        cidade: pa?.cidade,
        uf: pa?.uf,
        email: pa?.email,
        telefone: pa?.telefone,
      };
    }

    const p = r.user.pessoa;
    const ee = r.enderecoEntrega;
    return {
      rgInsc: p.rgInsc,
      documento: p.documento,
      cep: ee?.cep || p.cep,
      logradouro: ee?.logradouro || p.logradouro,
      enderecoNumero: ee?.enderecoNumero || p.enderecoEntrega,
      complemento: ee?.complemento || p.complemento,
      referencia: ee?.referencia || p.referencia,
      bairro: ee?.bairro || p.bairro,
      cidade: ee?.cidade || p.cidade,
      uf: ee?.uf || p.uf,
      email: r.email,
      telefone: p.telefone,
    };
  }
}

export class PedidoPaService extends PedidoService {
  async find(variables: PedidoPageConsultInput): Promise<PageConsult<Pedido>> {
    const result = await client
      .query({
        variables,
        query: gql`
          query (
            $userId: ID
            $q: String
            $status: String
            $first: Int!
            $page: Int!
            $sortField: String!
            $sortOrder: SortOrder!
          ) {
            r: pedidosPa(
              userId: $userId
              first: $first
              page: $page
              q: $q
              status: $status
              orderBy: {
                column: $sortField
                order: $sortOrder
              }
            ) {
              data {
                id
                createdAt
                updatedAt
                status
                total
                observacao
                user {
                  id
                  code
                  name
                  avatar
                }
              }
              paginatorInfo {
                count
                currentPage
                firstItem
                hasMorePages
                lastItem
                lastPage
                perPage
                total
              }
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async getById(id: number): Promise<Pedido> {
    const result = await client
      .query({
        variables: { id },
        query: gql`
          query ($id: ID!) {
            r: pedidoPa(id: $id) {
              id
              createdAt
              updatedAt
              status
              total
              totalItens
              frete
              desconto
              observacao
              dataPagamento
              itens {
                id
                itemId
                item {
                  id
                  nome
                  categoria {
                    nome
                  }
                }
                qtd
                valor
                total
              }
              codigoRastreamento
              entregas {
                valor
                servico
                retirarLocal
              }
              pagamentos {
                createdAt
                tipoPagamento {
                  descricao
                }
                valor
                status
              }
              historico {
                status
                createdAt
                mensagem
              }
              enderecoEntrega {
                contato
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                tipo
              }
              endereco {
                id
                contato
                cep
                logradouro
                enderecoNumero
                complemento
                bairro
                cidade
                uf
                referencia
                tipo
              }
              user {
                id
                code
                name
                avatar
                pessoa {
                  apelido
                  cep
                  logradouro
                  enderecoNumero
                  complemento
                  bairro
                  cidade
                  uf
                  referencia
                  documento
                  rgInsc
                  telefone
                }
              }
              pontoApoio {
                id
                apelido
              }
            }
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async changeStatus(
    id: number,
    status: string,
    mensagem?: string,
    extra?: any,
  ): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          status,
          mensagem,
          extra: JSON.stringify(extra),
        },
        mutation: gql`
          mutation($id: ID! $status: String! $mensagem: String $extra: Json) {
            r: changePedidoStatusPa(id: $id status: $status mensagem: $mensagem extra: $extra)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }

  async cancelar(id: number, mensagem?: string): Promise<boolean> {
    const result = await client
      .mutate({
        variables: {
          id,
          mensagem,
        },
        mutation: gql`
          mutation($id: ID! $mensagem: String) {
            r: cancelarPedidoPa(id: $id mensagem: $mensagem)
          }
        `,
      });
    const { data: { r } } = result;
    return r;
  }
}
